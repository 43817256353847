<template>
    <div>

        <div class="container">
            <edit api="brands" title="brand" :id="$route.params.id" :fs="brandFields" back="/admin/brands" @saved="saved"></edit>
        </div>
    </div>
</template>

<script>
import Edit from "@/components/Edit";
import { brandFields } from "@/config";
export default {
    props: ["id"],
    data() {
        return {
            brandFields: brandFields
        };
    },
    components: {
        Edit
    },
    methods: {
        saved() {
            this.$router.push("/admin/brands");
        }
    }
};
</script>